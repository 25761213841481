import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import 'github-markdown-css/github-markdown-light.css';

interface MessageProps {
  role: string;
  content: string;
  cost: string;
}

const MessageComponent: React.FC<MessageProps> = ({ role, content, cost }) => {
  return (
    <div className="message">
      {role === 'assistant' ? (
        <div className="markdown-body" style={{ fontSize: '20px' }}>
          <ReactMarkdown
            remarkPlugins={[remarkMath]}
            rehypePlugins={[rehypeKatex]}
          >
            {cost ? `${content}\n---\n${cost}` : content}
          </ReactMarkdown>
        </div>
      ) : (
        <div className="message-content-user" style={{ fontSize: '18px' }}>
          {`>>> ${content}`}
        </div>
      )}
    </div>
  );
};

export default MessageComponent;
